export const REDIRECT_SUCCESS_ALERT = 'REDIRECT_SUCCESS_ALERT';
export const MANUAL_ERROR_ALERT = 'MANUAL_ERROR_ALERT'
export const SERVER_ERROR_ALERT = 'SERVER_ERROR_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR"

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const LOGOUT_USER = "LOGOUT_USER"

export const UPDATE_USER_BEGIN = "UPDATE_USER_BEGIN"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const HANDLE_BET_CHANGE = "HANDLE_BET_CHANGE"
export const CLEAR_BET_STATE = "CLEAR_BET_STATE"
export const CLEAR_FILTER_STATE = "CLEAR_FILTER_STATE"

export const CREATE_BET_BEGIN = "CREATE_BET_BEGIN"
export const CREATE_BET_SUCCESS = "CREATE_BET_SUCCESS"
export const CREATE_BET_ERROR = "CREATE_BET_ERROR"

export const GET_BETS_BEGIN = "GET_BETS_BEGIN"
export const GET_BETS_SUCCESS = "GET_BETS_SUCCESS"

export const SET_EDIT_BET = "SET_EDIT_BET"
export const EDIT_BET_BEGIN = "EDIT_BET_BEGIN"
export const EDIT_BET_SUCCESS = "EDIT_BET_SUCCESS"
export const EDIT_BET_ERROR = "EDIT_BET_ERROR"

export const DELETE_BET_BEGIN = "DELETE_BET_BEGIN"

export const SHOW_STATS_BEGIN = "SHOW_STATS_BEGIN"
export const SHOW_STATS_SUCCESS = "SHOW_STATS_SUCCESS"

export const CHANGE_PAGE = "CHANGE_PAGE"