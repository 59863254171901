import Wrapper from '../assets/wrappers/BetInfo'

const BetInfo = ({ icon, text }) => {
    return (
        <Wrapper>
            <span className="icon">{icon}</span>
            <span className="text">{text}</span>
        </Wrapper>
    )
}

export default BetInfo;